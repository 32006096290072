import { styled } from '@mui/system'
import BHLink from '../../molecules/link'
import MuiBreadcrumbs from '@mui/material/Breadcrumbs'

const Root = styled(MuiBreadcrumbs)`
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: ${({ theme }) => theme.typography.pxToRem(10)};
`

const LinkCurrent = styled(BHLink)`
	letter-spacing: 0.36px;
	line-height: 1;
	font-weight: 500;
	font-size: ${({ theme }) => theme.typography.pxToRem(10)};
`

const LinkPrev = styled(BHLink)`
	color: #afafaf;
	letter-spacing: 0.36px;
	line-height: 1;
	font-weight: 500;
	font-size: ${({ theme }) => theme.typography.pxToRem(10)};
`

export { LinkCurrent, LinkPrev, Root }
